<template>
  <div class="flex itemsCenter spaceBetween header">
    <div class="toggleSideBar pointer">
      <slot name="left"></slot>
    </div>
    <div class="rig flex itemsCenter">
      <div class="money flex">
        <div class="pointer recharge" @click="rechargeDialog.show = true">充值</div>
        <div class="count">积分: <span>{{ $store.state.score }}</span></div>
      </div>
      <el-popover trigger="click" width="auto" popper-class="avatarPopover">
        <template #reference>
          <el-avatar :size="35" class="pointer avatar" src="https://avatars.githubusercontent.com/u/72015883?v=4" />
        </template>
        <template #default>
          <div class="popoverContent">
            <div class="popoverContentLi pointer" @click="showAgreement('yszc')">隐私政策</div>
            <div class="popoverContentLi pointer" @click="showAgreement('yhxy')">用户协议</div>
            <div class="popoverContentLi pointer" @click="logout">退出</div>
          </div>
        </template>
      </el-popover>
    </div>
    <el-dialog v-model="rechargeDialog.show" :show-close="false" custom-class="rechargeDialog">
      <template #header="{ close }">
        <div class="header">
          <el-button class="closeBtn" :icon="Close" @click="close"></el-button>
        </div>
      </template>
      <div class="con">
        <div class="tit center">选择合适的套餐，充值积分</div>
        <div class="packageList">
          <el-row :gutter="20">
            <el-col span="8" v-for="(item, index) in rechargeDialog.packageList" :key="index">
              <div class="item center pointer" @click="handlePackageClick(item.purseid)">
                <div class="title">{{ item.title }}</div>
                <div class="score">立即获得<span>{{ item.score }}</span>积分</div>
                <div class="money">￥<span>{{ item.price }}</span></div>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </el-dialog>
    <!-- 隐私政策和用户协议 -->
    <el-dialog v-model="agreementDialog.show" :show-close="false" custom-class="agreementDialog">
      <template #header="{ close }">
        <div class="header">
          <el-button class="closeBtn" :icon="Close" @click="close"></el-button>
        </div>
      </template>
      <div class="con">
        {{ agreementDialog.innerText }}
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { defineComponent, reactive, toRefs } from 'vue';
import { Close } from '@element-plus/icons-vue';
import { zhifubao } from '@/api/aiTools/script.js';  // 导入接口
import { loginout } from '@/api/index.js';  // 导入接口
import router from '@/router'
import * as $store from "../store";
export default defineComponent({
  name: "Header",
  computed: {
    $store() {
      return $store
    }
  },
  setup() {
    const pageData = reactive({
      // 充值
      rechargeDialog: {
        show: false,
        // 套餐列表
        packageList: [
          {purseid: 1, title: '月套餐1', score: 425, price: 85},
          {purseid: 2, title: '月套餐2', score: 2020, price: 340},
          {purseid: 3, title: '月套餐3', score: 6550, price: 1015},
          {purseid: 11, title: '年套餐11', score: 425, price: 970},
          {purseid: 12, title: '年套餐12', score: 2020, price: 3880},
          {purseid: 13, title: '年套餐13', score: 6550, price: 11790}
        ]
      },
      // 隐私政策、用户协议
      agreementDialog: {
        show: false,
        innerText: ''
      }
    });

    // 打开用户协议
    const showAgreement = (type) => {
      pageData.agreementDialog.innerText = type === 'yszc' ? '隐私政策内容' : '用户协议内容';
      pageData.agreementDialog.show = true;
    };

    // 退出登录
    const logout = async () => {
      try {
        // 调用接口进行登出
        await loginout();
        // 清除本地存储的用户数据
        for (const key in localStorage) {
    
          if (key.startsWith('user-') || key=="token") {
           localStorage.removeItem(key);
          }
        }

        // 跳转到登录页
        router.push('/login');
      } catch (error) {
        console.error("退出登录失败", error);
        ElMessage.error('退出登录失败，请重试');
      }
    };


    // 处理套餐点击事件
    const handlePackageClick = async (packageId) => {
      console.log('...................',packageId)
      try {
        const formData = {
          purseid: packageId, // 描述
        };
        const response = await zhifubao(formData);  // 调用接口
        if (response.code === 200) {
          // 成功响应，跳转到支付宝支付页面
          const alipayUrl = response.alipay; // 获取支付宝支付地址
          window.location.href = alipayUrl; // 跳转到支付宝支付页面
        } else {
          ElMessage.error(response.error || '充值失败');
        }

      } catch (error) {
        if (error.response && error.response.status === 401) {
          await router.push('/login');
        } else {
          console.error("11111", error);
        }
      }
    };

    return {
      ...toRefs(pageData),
      logout,
      Close,
      showAgreement,
      handlePackageClick
    };
  }
});
</script>

<style scoped lang="scss">
.header {
  .toggleSideBar {
    img {
      width: 14px;
      height: 14px;
    }
  }

  .rig {
    .money {
      border: 0.5px solid rgba(255, 255, 255, 0.6);
      margin-right: 20px;
      color: #fff;
      border-radius: 13px;
      overflow: hidden;

      * {
        line-height: 26px;
      }

      .recharge {
        padding: 0 10px;
        margin-right: 10px;
        border-right: 0.5px solid rgba(255, 255, 255, 0.6);

        &:hover {
          background-color: rgba(255, 255, 255, 0.2);
          color: #fff;
        }
      }

      .count {
        padding-right: 10px;

        span {
          color: rgba(255, 255, 255, 0.6);
        }
      }
    }
  }
}
</style>
