import { createRouter, createWebHistory, createWebHashHistory } from 'vue-router'
import Main from '@/components/Main.vue'
import AiToolsDetail from '@/components/AiToolsDetail.vue'

//路由前缀，用于配合后端放置静态文件夹 system 为文件夹名称
// global.preStr = '';

const routes = [
  {
    path: '/login',
    name: 'login',
    component: () => import('@/components/Login.vue')
  },
  {
    path: '/',
    name: 'index',
    component: () => import('@/views/Index.vue'),
  },
  {
    path: '/to',
    name: 'topup',
    component: () => import('@/components/topupPage.vue'),
  },



  {
    path: '/',
    name: 'yourPath',
    component: () => import('@/views/Index.vue'),
    meta: {
      title: '元界AI',
      keywords: 'AI生成, 剧本生成, 视频生成, 元界AI, 创意工具, 配音, 音效',
    },
  },



  {
    path: '/aiTools',
    component: Main,
    redirect: '/aiTools/index',
    children: [
      {
        path: '/aiTools/index',
        name: 'aiToolsIndex',
        component: () => import('@/views/aiTools/Index.vue'),
        meta: {
          keepAlive: true // 需要缓存页面
        }
      },
      {
        path: '/aiTools/personalCenter',
        name: 'personalCenter',
        component: () => import('@/views/aiTools/PersonalCenter.vue'),
        meta: {
          keepAlive: true // 需要缓存页面
        }
      }
    ]
  },
  {
    path: '/aiTools/detail',
    component: AiToolsDetail,
    redirect: '/aiTools/detail/script',
    children: [
      {
        path: '/aiTools/detail/script',
        name: 'aiToolsScript',
        component: () => import('@/views/aiTools/detail/Script.vue'),
        meta: {
          keepAlive: true // 需要缓存页面
        }
      },
      {
        path: '/aiTools/detail/scenic',
        name: 'aiToolsScenic',
        component: () => import('@/views/aiTools/detail/Scenic.vue'),
        meta: {
          keepAlive: true // 需要缓存页面
        }
      },
      {
        path: '/aiTools/detail/video',
        name: 'aiToolsVideo',
        component: () => import('@/views/aiTools/detail/Video.vue'),
        meta: {
          keepAlive: true // 需要缓存页面
        }
      },
      {
        path: '/aiTools/detail/audio',
        name: 'aiToolsAudio',
        component: () => import('@/views/aiTools/detail/Audio.vue'),
        meta: {
          keepAlive: true // 需要缓存页面
        }
      },
      {
        path: '/aiTools/detail/voice',
        name: 'aiToolsVoice',
        component: () => import('@/views/aiTools/detail/Voice.vue'),
        meta: {
          keepAlive: true // 需要缓存页面
        }
      },
    ]
  },
]

const router = createRouter({
  // history: createWebHistory(process.env.BASE_URL),
  history: createWebHashHistory(process.env.BASE_URL), // 使用 HTML5 历史模式
  routes
})

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  if (to.meta.keywords) {
    let metaKeywords = document.querySelector('meta[name="keywords"]');
    if (metaKeywords) {
      metaKeywords.content = to.meta.keywords;
    } else {
      metaKeywords = document.createElement('meta');
      metaKeywords.name = 'keywords';
      metaKeywords.content = to.meta.keywords;
      document.head.appendChild(metaKeywords);
    }
  }
  next();
});


// router.beforeEach((to, from, next) => {
//   next()
// })

export default router
