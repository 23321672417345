<template>
  <div class="sendMessage messageBox">
    <div class="fileUploadWrapper" v-if="!hideAddBtn">
      <label for="file">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 337 337">
          <circle stroke-width="20" stroke="#6c6c6c" fill="none" r="158.5" cy="168.5" cx="168.5"></circle>
          <path stroke-linecap="round" stroke-width="25" stroke="#6c6c6c" d="M167.759 79V259"></path>
          <path stroke-linecap="round" stroke-width="25" stroke="#6c6c6c" d="M79 167.138H259"></path>
        </svg>
        <span class="tooltip">Add an image</span>
      </label>
      <input type="file" id="file" name="file" />
    </div>
    <input :placeholder="placeholder" type="text" id="messageInput"  @keydown.enter.native="sendMsg" v-bind="$attrs" v-on="$listeners" v-model="message" :disabled="disabled || loading"/>
    <button id="sendButton" @click="sendMsg" :disabled="disabled || loading">
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 664 663">
        <path fill="none"
              d="M646.293 331.888L17.7538 17.6187L155.245 331.888M646.293 331.888L17.753 646.157L155.245 331.888M646.293 331.888L318.735 330.228L155.245 331.888">
        </path>
        <path stroke-linejoin="round" stroke-linecap="round" stroke-width="33.67" stroke="#6c6c6c"
              d="M646.293 331.888L17.7538 17.6187L155.245 331.888M646.293 331.888L17.753 646.157L155.245 331.888M646.293 331.888L318.735 330.228L155.245 331.888">
        </path>
      </svg>
    </button>
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue';

export default defineComponent({
  name: "sendMessage",
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: ""
    },
    hideAddBtn: {
      type: Boolean,
      default: false
    },
    sendFn: {
      type: Function,
      required: true
    }
  },
  setup(props) {
    const message = ref("");
    const loading = ref(false); // 添加 loading 状态

    const sendMsg = async () => {
      if(props.sendFn){
        loading.value = true; // 发送消息时设置 loading 为 true
        await props.sendFn(message.value);
        message.value = "";
        loading.value = false; // 发送完成后设置 loading 为 false
      }
    }

    return {
      message,
      sendMsg,
      loading // 导出 loading 状态
    }
  }
})
</script>

<style scoped lang="scss">
.messageBox {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #2d2d2d;
  padding: 0 15px;
  border-radius: 10px;
  border: 0.5px solid rgb(63, 63, 63);
}

.messageBox:focus-within {
  border: 0.5px solid rgb(110, 110, 110);
}

.fileUploadWrapper {
  width: fit-content;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Arial, Helvetica, sans-serif;
}

#file {
  display: none;
}

.fileUploadWrapper label {
  cursor: pointer;
  width: fit-content;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.fileUploadWrapper label svg {
  height: 18px;
}

.fileUploadWrapper label svg path {
  transition: all 0.3s;
}

.fileUploadWrapper label svg circle {
  transition: all 0.3s;
}

.fileUploadWrapper label:hover svg path {
  stroke: #fff;
}

.fileUploadWrapper label:hover svg circle {
  stroke: #fff;
  fill: #3c3c3c;
}

.fileUploadWrapper label:hover .tooltip {
  display: block;
  opacity: 1;
}

.tooltip {
  position: absolute;
  top: -40px;
  display: none;
  opacity: 0;
  color: white;
  font-size: 10px;
  text-wrap: nowrap;
  background-color: #000;
  padding: 6px 10px;
  border: 0.5px solid #3c3c3c;
  border-radius: 5px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.596);
  transition: all 0.3s;
}

#messageInput {
  flex-grow: 1;
  width: 200px;
  height: 100%;
  background-color: transparent;
  outline: none;
  border: none;
  padding-left: 10px;
  color: white;
}

#messageInput:focus~#sendButton svg path,
#messageInput:valid~#sendButton svg path {
  fill: #3c3c3c;
  stroke: white;
}

#sendButton {
  width: fit-content;
  height: 100%;
  background-color: transparent;
  outline: none;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s;
}

#sendButton svg {
  height: 18px;
  transition: all 0.3s;
}

#sendButton svg path {
  transition: all 0.3s;
}

#sendButton:hover svg path {
  fill: #3c3c3c;
  stroke: white;
}
</style>
