import {get, post} from './axios.js';

//登录
let login = (param = {}) => {
    return post('/login/', param);
}

let register = (param = {}) => {
    return post('/register/', param);
}

let email = (param = {}) => {
    return post('/api/senmail/', param);
}

let forget = (param = {}) => {
    return post('/forgetpass/', param);
}

let loginout = (param = {}) => {
    return post('/loginout/', param);
}


let img = (param = {}) => {
    const { page = 1, size = 10, self = 0 } = param;
    return get(`/get_img_lists/?page=${page}&size=${size}&self=${self}`);
};

let latest = (param = {}) => {
    const { page = 1, size = 10, } = param;
    return get(`/get_img_lists/?page=${page}&size=${size}&order=time`);
};

let video = (param = {}) => {
    const { page = 1, size = 10, self = 0 } = param;
    return get(`/get_video_lists/?page=${page}&size=${size}&self=${self}`);
};

let sound = (param = {}) => {
    const { page = 1, size = 101010101, sty = 0 } = param;
    return get(`/get_sound_lists/?page=${page}&size=${size}&sty=${sty}`);
};

let audio = (param = {}) => {
    const { page = 1, size = 10 } = param;
    return get(`/get_audio_lists/?page=${page}&size=${size}`);
};

let like = (param = {}) => {
    return post('/do_like/ ', param);
}




let avatar = async (param = {}) => {
    try {
        const response = await post('upload_image_oss/', param);

        // 如果 `response` 已经是数据对象，直接返回它
        return response;
    } catch (error) {
        console.error('API 请求出错:', error);
        throw error;
    }
};









/**
 * 上传文件
 * imageFile 文件对象
 * fileType  1 用户头像 2 企业icon 3 任务缩略图 4 主任务中的附件 5 子任务缩略图 6 子任务中的附件 7 稿件 100 批量添加用户
 */
let uploadFile = (param = {}) => {
    let formData = new FormData();
    formData.set('imageFile', param.imageFile);
    return post('/api/file/upload?fileType=' + param.fileType, formData);
};

export {
    login,
    register,
    latest,
    email,
    avatar,
    loginout,
    uploadFile,
    like,
    img,
    audio,
    sound,
    video,
    forget,
}