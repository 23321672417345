<template>
  <div :class="{ 'default': !$attrs.type, [$attrs.type]: true }">
    <button class="customButton" v-bind="$attrs" v-on="$listeners">
      <slot></slot>
    </button>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
export default defineComponent({
  name: "customButton",
  setup() {

  }
})
</script>

<style scoped lang="scss">
.plain {
  .customButton {
    position: relative;
    background: none;
    border: 0.5px solid black;
    border-radius: 5px;
    min-width: 80px;
    line-height: 40px;
    text-align: center;
    transition: all 0.3s;
    &.around{
      border-radius: 20px;
    }
  }

  .customButton::before {
    content: "";
    height: 0%;
    width: 0%;
    top: 50%;
    left: 50%;
    z-index: -1;
    position: absolute;
    background-color: #494958;
    transition: all 0.3s;
  }

  .customButton:hover {
    border: 0.5px solid #32323c;
    color: white;
  }

  .customButton:hover::before {
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }

}

.default {

  .customButton {
    cursor: pointer;
    width: 100%;
    background-color: rgba(#fff, 0.8);
    line-height: 40px;
    font-size: 16px;
    letter-spacing: 2px;
    text-transform: uppercase;
    display: inline-block;
    text-align: center;
    padding: 0 10px;
    border: 0.5px solid black;
    position: relative;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.1);
    color: black;
    text-decoration: none;
    transition: 0.3s ease all;
    z-index: 1;
    overflow: hidden;
    &.around{
      border-radius: 20px;
    }
  }

  .customButton:before {
    transition: 0.5s all ease;
    position: absolute;
    top: 0;
    left: 50%;
    right: 50%;
    bottom: 0;
    opacity: 0;
    content: '';
    background-color: black;
    z-index: -1;
  }

  .customButton:hover,
  .customButton:focus {
    color: white;
  }

  .customButton:hover:before,
  .customButton:focus:before {
    transition: 0.5s all ease;
    left: 0;
    right: 0;
    opacity: 1;
  }

  .customButton:active {
    transform: scale(0.9);
  }
}
</style>