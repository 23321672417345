import router from '@/router'
import store from '@/store'
import axios from 'axios'

// axios.defaults.baseURL = "http://127.0.0.1:8888";
axios.defaults.baseURL = "https://www.yuanjieai.com/api/";
//响应体code码
const code = {
    success: 200
}

//服务端使用
// if (global.location.href.indexOf("") !== -1) {
//     axios.defaults.baseURL = "";
// }

axios.interceptors.request.use(async config => {
    let token = global.localStorage.getItem('token') || '';
    config.headers['Authorization'] = 'Bearer ' + token;
    //如果自定义的域名前缀，则拼接上
    if (global.baseUrl) {
        config.url = global.baseUrl + config.url;
    }

    return config;
}, error => {
    // 请求错误处理
    return Promise.reject(error)
})





// 添加响应拦截器
axios.interceptors.response.use(response => {
    if (!response) {
        return null;
    } else {

        response.data = response.data || {};
        //错误提示
        if (response.data.code !== code.success) {
            global.ElMessage({
                message: response.data.message || 'error',
                type: 'error',
                duration: 1000,
            });
            return null;
        // } else if (response.config.url.indexOf('/api/user/regist') !== -1) {
        //     return response;
        } else {
                //实际数据为 dataObj.data , score_change 和 score 与 dataObj.data 同级
                const dataObj = response.data.data || {};
                //响应中有 score_change 字段并且值为 true，则表示有积分变动，更新用户积分
                if (dataObj.score_change) {
                    //更新用户积分
                    store.commit('setScore', dataObj.score);
                }
                //登录接口，成功之后，将token存储在本地
                if(response.config.url.indexOf('/login/') !== -1){
            
                    global.localStorage.setItem("token", response.data.data.data.access_token);
                }
                return response;
            // }
            // else if (response.data.code === 202) {
            //     //如果token更新了，则同步更新本地的token
            //     global.localStorage.setItem("token", response.headers['authorization']);
            //     return response;
            
        }
    }
}, error => {
    //token解析错误，跳转到登录页
    if (error.response && error.response.status === 401 || error.code === 'ERR_NETWORK') {
        // router.push('/login');//临时屏蔽
         router.push('/login');
    }
    return Promise.reject(error);
})

export function get(url, params = {}, headers = {}) {
    return new Promise((resolve, reject) => {
        axios({
            url: url,
            method: "GET",
            headers,
            params
        }).then(
            response => {
                if (response) {
                    resolve(response.data);
                }
            },
            err => {
                reject(err);
            }
        );
    });
}
export function post(url, data = {}, headers = {}) {
    return new Promise((resolve, reject) => {
        axios({
            url: url,
            method: 'POST',
            headers,
            data
        }).then(response => {
            if (response) {
                resolve(response.data);
            }
        }, err => {
            reject(err);
        });
    })
}

//下载文件
export function download(url, params = {}, headers = {}) {
    return new Promise((resolve, reject) => {
        axios({
            url: url,
            method: "GET",
            responseType: "blob",
            headers,
            params
        }).then(
            response => {
                if (response) {
                    resolve(response);
                }
            },
            err => {
                reject(err);
            }
        );
    });
}



/**
* 将url文件下载到本地
* @param filePath {String} 文件链接
* @param fileName {String} 文件名字
* @param manuScriptID {String} 稿件id
* @param progress 进度回调
* @return void
*/
// export async function downloadFile({ filePath, fileName, fileType, id, progress, error, success }) {
//     let url = axios.defaults.baseURL + "/api/file/download";
//     let blob = await getBlob(url, { filePath, fileType, id, progress, error, success });
//     if (typeof blob === 'string') {
//         if (error) {
//             error();
//         }
//     } else {
//         success();
//         saveFile(blob, fileName);
//     }
// }
// function getBlob(url, { fileType, filePath, id, progress, error }) {
//     return new Promise(resolve => {
//         //上次的下载量
//         let preLoad = 0;
//         //上次时间
//         let preTime;
//         const xhr = new XMLHttpRequest();
//         xhr.open('POST', url, true);
//         let token = global.localStorage.getItem('token') || '';
//         xhr.setRequestHeader('Authorization', 'Bearer ' + token);
//         xhr.setRequestHeader('Content-Type', 'application/json');
//
//
//         //监听进度事件
//         xhr.addEventListener(
//             'progress',
//             function (evt) {
//                 if (evt.lengthComputable) {
//                     //记录初始时间
//                     if (!preTime) {
//                         preTime = evt.timeStamp;
//                     } else {
//                         if (evt.timeStamp - preTime > 1000) {
//                             let percentComplete = evt.loaded / evt.total;
//                             // percentage是当前下载进度，可根据自己的需求自行处理
//                             let percentage = percentComplete * 100;
//                             preTime = null;
//                             progress({ percentage: percentage.toFixed(1), netSpeed: Math.floor((evt.loaded - preLoad) / 1000) });
//                             preLoad = evt.loaded;
//                         }
//                     }
//                 }
//             },
//             false
//         );
//         xhr.responseType = 'blob';
//         xhr.onload = () => {
//             if (xhr.status === 200) {
//                 resolve(xhr.response);
//             } else {
//                 if (error) {
//                     error("无权限访问此资源");
//                 }
//             }
//         };
//         xhr.send(JSON.stringify({
//             fileType: fileType,
//             url: filePath || '',
//             param: id || ''
//         }));
//     });
// }
// //保存文件
// function saveFile(blob, fileName) {
//     // ie的下载
//     if (window.navigator.msSaveOrOpenBlob) {
//         navigator.msSaveBlob(blob, filename);
//     } else {
//         // 非ie的下载
//         const link = document.createElement('a');
//         const body = document.querySelector('body');
//
//         link.href = window.URL.createObjectURL(blob);
//         link.download = fileName;
//
//         // fix Firefox
//         link.style.display = 'none';
//         body.appendChild(link);
//
//         link.click();
//         body.removeChild(link);
//
//         window.URL.revokeObjectURL(link.href);
//     }
// }