<template>
	<el-container class="aiToolsDetailmainContainer">
        <el-header class="header">
            <Header>
                <template v-slot:left>
                    <div class="backBtn pointer" @click="toAiToolsHome">
						<Back class="icon"/>
						<div class="text inlineBlock">返回</div>
					</div>
                </template>
            </Header>
        </el-header>
        <el-main class="main">
            <router-view></router-view>
        </el-main>
	</el-container>
</template>

<script>
import { defineComponent } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import Header from './Header.vue';

export default defineComponent({
	name: 'aiToolsDetailMainContainer',
	components: {
		Header
	},
	setup() {
		let store = useStore()
		const router = useRouter();
		const toAiToolsHome = ()=>{
			router.push('/aiTools/index');
			//记录当前点击的路由id
			store.commit('setCurrentActive', '1-1');
		}
		return {
			toAiToolsHome
		};
	},
})
</script>

<style scoped lang="scss">
$header_h: 64px;

.aiToolsDetailmainContainer {
	background-color: rgb(15, 17, 21);
	height: 100%;
	color: #fff;

	.header {
		line-height: $header_h;
		height: $header_h;
		color: rgba($color: #fff, $alpha: 0.6);
        .backBtn{
            padding: 0 20px;
            line-height: 30px;
            color: #fff;
            border: 0.5px solid #727272;
            border-radius: 5px;
            opacity: 0.6;
			.icon{
				width: 20px;
				margin-right: 10px;
				transform: translate(0, 5px);
			}
			&:hover{
				.text{
					transform: scale(1.1);
				}
				.icon{
					transform: translate(-5px, 5px);
				}
			}
        }
	}
	.main {
		height: calc(100vh - $header_h);
		overflow: auto;
		padding: 20px;
	}
}
</style>
