import {get, post} from '../axios.js';

//创建剧本
export const createScript = (data) => {
  return post('/chat/', data);
}

export const getHistory  = (data) => {
  return get('/chat/', data);
}



export const zhifubao  = (data) => {
  return post('/alipay_new/', data);
}





import axios from 'axios';

// 重新命名 get 函数以避免冲突
const fetchData = (url, data) => {
  return axios.get(url, {
    params: data
  });
};

export const topu = (data) => {
  return fetchData('/alipayx/result/', data);
};
