import { createStore } from 'vuex'

export const state = {
    //loading
    loading: false,
    //积分
    score: 0,
    //默认点击的菜单id
    defaultActive: '1-1',
    //左侧导航
    menuList: [
        {
            id: '1',
            title:'',
            list:[
                {
                    id: '1-1',
                    name: "首页",
                    icon: 'House',
                    path: '/aiTools/index'
                },
                {
                    id: '1-2',
                    name: "个人主页",
                    icon: 'User',
                    path: '/aiTools/personalCenter'
                },
            ]
        },
        {
            id: '2',
            title:'AI 工具',
            list:[
                {
                    id: '2-1',
                    name: "剧本",
                    icon: 'Reading',
                    path: '/aiTools/detail/script'
                },
                {
                    id: '2-2',
                    name: "分镜",
                    icon: 'Picture',
                    path: '/aiTools/detail/scenic'
                },
                {
                    id: '2-3',
                    name: "视频",
                    icon: 'VideoCamera',
                    path: '/aiTools/detail/video'
                },
                {
                    id: '2-4',
                    name: "音效",
                    icon: 'Operation',
                    path: '/aiTools/detail/audio'
                },
                {
                    id: '2-5',
                    name: "配音",
                    icon: 'Mic',
                    path: '/aiTools/detail/voice'
                },
            ]
        }
    ],
    //图片、视频查看器
    preViewDialog: {
        show: false,
        type: 0, // 0 图片 1 视频
        data: {}
    }
};
export default createStore({
    state,
    getters: {
        isLoading: state => state.loading,
    },
    mutations: {
        setCurrentActive(state, active) {
            state.defaultActive = active
        },
        setPreViewDialog(state, data) {
            state.preViewDialog = data
        },
        setScore(state, score) {
            state.score = score;
            localStorage.setItem('score', score);
        },
        setLoading(state, status) {
            state.loading = status;
        }
    },
    actions: {
        async performAction({ state, commit }, newScore) {
            if (state.score <= 0) {
                commit('setLoading', false);
                alert('积分不足');
                return;
            }

            commit('setLoading', true);

            try {
                // 模拟异步操作
                await new Promise((resolve) => setTimeout(resolve, 2000));
                commit('setScore', newScore); // 这个操作依赖于积分 store
            } catch (error) {
                console.error('操作失败', error);
            } finally {
                commit('setLoading', false);
            }
        }
    },
    modules: {
        
    }
})
