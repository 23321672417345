import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import CustomButton from '@/components/CustomButton.vue'
import SendMessage from '@/components/SendMessage.vue'
import { Button, Icon } from 'vant';
import 'vant/lib/index.css';
import { createHead } from '@vueuse/head';

const head = createHead();

// import '@/assets/css/elementCustom.scss';
import ElementPlus,{ ElMessage, ElMessageBox, genFileId } from 'element-plus';
import 'element-plus/dist/index.css'
import '@/assets/css/elementCustomRootStyle.scss';

import locale from 'element-plus/es/locale/lang/zh-cn';

import * as ElIcon from '@element-plus/icons-vue';

//UI库
import VueAmazingUI from 'vue-amazing-ui'
import 'vue-amazing-ui/css'

//瀑布流
import MasonryLayout from '@/components/MasonryLayout.vue'

let app = createApp(App);

//设置默认语言为中文
app.use(ElementPlus, { locale });

app.component('MasonryLayout', MasonryLayout);

//注册全部图标
for (let name in ElIcon) {
    app.component(name, ElIcon[name]);
}

app.component('CustomButton', CustomButton);
app.component('SendMessage', SendMessage);

import '@/assets/css/common.scss'

import '@/utils/index.js'

global.ElMessage = ElMessage;
global.ElMessageBox = ElMessageBox;
global.genFileId = genFileId;

//判断是否登录
// if (!global.localStorage.getItem('user-isLogin')) {
//     setTimeout(() => {
//         global.toPage('/login');
//     }, 500);
// }

//恢复积分
const score = localStorage.getItem('score') || 0;
store.commit('setScore', score);

app.use(store);
app.use(Button);
app.use(Icon);
app.use(head);
app.use(router);
app.use(ElementPlus, { size: 'middle', zIndex: 3000 });
app.use(VueAmazingUI);
app.mount('#app');

