import axios from 'axios';
import router from './../router'

//获取图片路径
let getImgPath = (filePath) => {
    return filePath ? axios.defaults.baseURL + filePath : '';
}


//格式化时间戳
let formatDate = (dateNum) => {
    if (!dateNum) {
        return "";
    }
    let date = new Date(+dateNum)
    return (
        date.getFullYear() +
        '-' +
        (date.getMonth() + 1) +
        '-' +
        date.getDate() +
        ' ' +
        date.getHours() +
        ':' +
        date.getMinutes() +
        ':' +
        date.getSeconds()
    );
}

//跳转路由
global.toPage = (path) => {
    global.router = router;
    router.push(path);
}

export {
    getImgPath,
    formatDate
}