<template>
  <el-container class="mainContainer">
    <el-aside class="aside">
      <div class="logo flex itemsCenter pointer" @click="$router.push('/');">
        <img src="@/assets/imgs/logo.png" alt="">
        <img src="@/assets/imgs/logoSub.png" alt="">
      </div>
      <div class="asideMenu">
        <div v-for="(group, idx) in menuList" :key="idx" class="group">
          <div class="tit" v-if="group.title">{{ group.title }}</div>
          <div class="groupList">
            <div v-for="(item, itemIndex) in group.list" :key="itemIndex" class="groupItem pointer flex itemsCenter" :class="{ active: item.id === defaultActive }" @click="menuActive(item)">
              <House v-if="item.icon === 'House'" class="icon"/>
              <User v-if="item.icon === 'User'" class="icon"/>
              <Reading v-if="item.icon === 'Reading'" class="icon"/>
              <Picture v-if="item.icon === 'Picture'" class="icon"/>
              <VideoCamera v-if="item.icon === 'VideoCamera'" class="icon"/>
              <Operation v-if="item.icon === 'Operation'" class="icon"/>
              <Mic v-if="item.icon === 'Mic'" class="icon"/>
              <div>{{ item.name }}</div>
            </div>
          </div>
        </div>
      </div>
    </el-aside>
    <el-container>
      <el-header class="header">
        <Header></Header>
      </el-header>
      <el-main class="main" id="main">
        <router-view></router-view>
      </el-main>
    </el-container>
    <!-- 图片、视频查看器 -->
    <el-dialog v-model="preViewDialog.show" :show-close="false" custom-class="agreementDialog">
      <template #header="{ close }">
        <div class="agreementDialogHeader">
          <el-button class="closeBtn" :icon="Close" @click="closeDialog"></el-button>

        </div>
      </template>
      <div class="preViewContainer">
        <div class="preViewMedia">
          <img v-if="preViewDialog.type === 0" class="preViewItem" :src="preViewDialog.data.answer" alt="图片预览" />
          <video v-if="preViewDialog.type === 1" class="preViewItem video" controls :src="preViewDialog.data.src"/>
        </div>
        <div class="preViewDetails">
          <div class="header flex itemsCenter">
            <div class="avatar">
              <img :src="preViewDialog.data.avatar" alt="用户头像" />
            </div>

            <div class="preViewUser">{{preViewDialog.data.nickname}}</div>
          </div>
          <div class="preViewQuestion">生成词: {{ preViewDialog.data.question }}</div>
          <div class="preViewQuestion">图片比例: {{ preViewDialog.data.proportion }}</div>


          <van-button
              ref="likeButton"
              class="likeButton"
              type="primary"
              :class="{ liked: liked, 'not-liked': !liked }"
              @click="toggleLike"
          >
            <van-icon :name="liked ? 'like' : 'like-o'" />
            <span>{{ preViewDialog.data.like_num }}</span>
          </van-button>

          <!--          <div>喜欢: {{ likeCount }}</div>-->
        </div>
      </div>
    </el-dialog>
  </el-container>
</template>



<script>
import {computed, defineComponent, ref, watch} from 'vue'
import { useStore } from 'vuex'
import {Document, Setting} from '@element-plus/icons-vue'
import { useRouter } from 'vue-router'
import Header from './Header.vue';
import { like } from "@/api/index.js";
import { Close} from '@element-plus/icons-vue';
import { Button as VanButton, Icon as VanIcon } from 'vant';




export default defineComponent({
	name: 'MainContainer',
	components: {
		Document,
		Setting,
		Header,
    Close,
    VanButton,
    VanIcon
	},
  setup() {
    const store = useStore();
    const router = useRouter();

    //图片、视频查看器
    const preViewDialog = computed(() => {
      return store.state.preViewDialog;
    })

    const liked = ref(false);
    const likeCount = computed(() => preViewDialog.value.data.like_num || 0);

    const menuActive = (item) => {
      if (item.path) {
        router.push(item.path);
      }
      store.commit('setCurrentActive', item.id);
    };

    watch(() => store.state.preViewDialog, (newVal) => {
      preViewDialog.value = { ...newVal };
      liked.value = newVal.data ? newVal.data.liked : false;
    }, { immediate: true });






    const toggleLike = async () => {
      // 记录当前的点赞状态
      const currentLiked = preViewDialog.value.data.liked;

      // 切换点赞状态
      preViewDialog.value.data.liked = !currentLiked;

      try {
        // 发送点赞请求
        const response = await like({
          s_ty: preViewDialog.value.type === 0 ? 1 : 2,
          s_id: preViewDialog.value.data.id,
        });

        if (response.code === 200) {
          // 根据接口返回的数据更新点赞数
          preViewDialog.value.data.like_num = response.like_num;

          // 根据接口返回的 message 更新点赞状态
          if (response.message === "Unliked") {
            preViewDialog.value.data.liked = false;
            liked.value = false;
          } else if (response.message === "Liked") {
            preViewDialog.value.data.liked = true;
            liked.value = true;
          }
        } else {
          console.error('点赞失败:', response.message);
          // 如果接口返回错误，恢复之前的点赞状态
          preViewDialog.value.data.liked = currentLiked;
          liked.value = currentLiked;
        }
      } catch (error) {
        console.error("点赞请求失败:", error);
        // 如果请求失败，恢复之前的点赞状态
        preViewDialog.value.data.liked = currentLiked;
        liked.value = currentLiked;
      }
    };




    const closeDialog = () => {
      preViewDialog.value.show = false;
    };




    const menuList = computed(() => store.state.menuList);
    const defaultActive = computed(() => store.state.defaultActive);

    return {
      menuActive,
      menuList,
      defaultActive,
      preViewDialog,
      toggleLike,
      Close,
      closeDialog,
      liked,
      likeCount,
      VanButton,
      VanIcon
    };
  }


})
</script>

<style scoped lang="scss">
$header_h: 64px;

.mainContainer {
  background-color: rgb(15, 17, 21);
  height: 100%;
  color: #fff;

  .header {
    line-height: $header_h;
    height: $header_h;
    color: #333;
  }
  //.avatar {
  //  background-color: #3b3b3b;
  //  width: 80px;
  //  height: 80px;
  //  border-radius: 50%;
  //  overflow: hidden; /* 隐藏超出的部分 */
  //  display: flex;
  //  align-items: center;
  //  justify-content: center;
  //
  //  img {
  //    width: 100%; /* 让图片填满父容器 */
  //    height: 100%;
  //    object-fit: cover; /* 保持图片的宽高比，裁剪超出的部分 */
  //  }
  //}


  .aside {
    width: 220px;
    border-right: 0.5px solid #202020;

    .logo {
      height: $header_h;

      img {
        height: 30px;
        padding-left: 20px;
      }
    }

    .asideMenu {
      height: calc(100% - $header_h);
      padding-left: 20px;
      padding-right: 20px;
      .group {
        border-top: 0.5px solid #202020;
        border-bottom: 0.5px solid #202020;
        padding-top: 20px;
        padding-bottom: 20px;
        &:last-child {
          border-bottom: none;
        }
        .tit {
          font-size: 12px;
          color: rgba($color: #fff, $alpha: 0.5);
          margin-bottom: 10px;
        }
        .groupList {
          .groupItem {
            padding-left: 30px;
            height: 40px;
            border-radius: 10px;
            margin-bottom: 5px;
            color: rgba(255, 255, 255, .6);
            .icon {
              width: 18px;
              margin-right: 10px;
            }
            &:hover,
            &.active {
              background-color: rgba(204, 221, 255, .06);
              color: rgb(255, 255, 255);
            }
          }
        }
      }
    }
  }

  .main {
    height: calc(100vh - $header_h);
    overflow: auto;
    padding: 20px;
  }

  .preViewContainer {
    display: flex;
    align-items: center;
    width: 100% !important;
    max-width: 1200px !important;
    gap: 5px; /* Space between media and details */
    justify-content: flex-start;
  }

  .preViewMedia {
    display: flex;
    align-items: center;
    flex-shrink: 0;
  }

  .preViewItem {
    max-width: 600px; /* 放大调整宽度 */
    max-height: 80vh;  /* 最大高度 */
    object-fit: contain;
  }

  .video {
    max-width: 400px; /* Adjust based on your preference */
    max-height: 80vh; /* Adjust based on your preference */
  }

  .preViewDetails {
    color: #fff;
    max-width: 600px; /* 增加最大宽度 */
    text-align: left;
    //padding-left: 10px; // 文字右边框的间距
    .avatar {
      background-color: #3b3b3b;
      width: 80px;
      height: 80px;
      border-radius: 50%;
      overflow: hidden; /* 隐藏超出的部分 */
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 100%; /* 让图片填满父容器 */
        height: 100%;
        object-fit: cover; /* 保持图片的宽高比，裁剪超出的部分 */
      }
    }

    .header {
      .avatar {
        background-color: #3b3b3b;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        margin-right: 10px;
      }

      .preViewUser {
        color: #fff;
      }
    }

  .preViewQuestion {
    margin-bottom: 110px;
    padding-left: 10px; // 文字右边框的间距
  }

  .likeButton {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #566166 !important; /* 自定义按钮背景色 */
    border: none !important; /* 去掉按钮边框 */

    .van-icon {
      transition: color 0.3s;
      font-size: 30px; /* Adjust size as needed */
      margin-right: 10px; /* Space between icon and count */
    }

    &.liked .van-icon {
      color: #e60d0d; /* 红色 */
    }

    &.not-liked .van-icon {
      color: #1547ab; /* 默认颜色 */
    }

  }
  }
}
</style>




