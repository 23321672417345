<template>
	<div class="masonry-container" v-bind="$attrs">
		<div class="masonry-column" :style="{ width: `calc(${100 / columnsCount}% - ${(columnsCount - 1) * gutter}px)`, margin: `0 ${gutter}px` }" v-for="(column, index) in columns" :key="index">
			<div class="masonry-item" v-for="item in column" :key="item.id">
				<slot :item="item"></slot>
			</div>
		</div>
		<div ref="bottomObserver" class="bottom-observer"></div>
	</div>
</template>

<script>
export default {
	name: 'MasonryLayout',
	props: {
		items: {
			type: Array,
			required: true
		},
		columnsCount: {
			type: Number,
			default: 3
		},
		gutter: {
			type: Number,
			default: 10
		}
	},
	data() {
		return {
			observer: null
		}
	},
	computed: {
		columns() {
			const columns = Array.from({ length: this.columnsCount }, () => [])
			this.items.forEach((item, index) => {
				const columnIndex = index % this.columnsCount
				columns[columnIndex].push(item)
			})
			return columns
		}
	},
	mounted() {
		this.observer = new IntersectionObserver(this.handleIntersection, {
			root: null, //指定根元素，用于检查目标元素的可见性。如果设置为 null，则使用浏览器视口作为根元素。
			rootMargin: '0px',
			threshold: 1.0
		})
		this.observer.observe(this.$refs.bottomObserver)
	},
	beforeDestroy() {
		if (this.observer) {
			this.observer.disconnect()
		}
	},
	methods: {
		handleIntersection(entries) {
			entries.forEach(entry => {
				if (entry.isIntersecting) {
					this.$emit('load-more')
				}
			})
		}
	}
}
</script>

<style lang="scss" scoped>
.masonry-container {
	display: flex;
	justify-content: space-between;
}

.masonry-column {
	flex: 1;
	display: flex;
	flex-direction: column;
}

.masonry-item {
	margin-bottom: 20px;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
	border-radius: 8px;
	overflow: hidden;
}

.bottom-observer {
	height: 10px;
	align-self: self-end;
}
</style>